@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    
}

html{
  scroll-behavior: smooth;
}

body{
  margin: 0px !important;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

/* width */
/* ::-webkit-scrollbar {
    width: 15px;
    height: 80px;
  }
  
  ::-webkit-scrollbar-track {
    background:#222A35;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #575C66;
    border-radius: 6px;
  }
  

  ::-webkit-scrollbar-thumb:hover {
    background: #626970;
  } */

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: var(--red);
  background-color: #0000cc;
  transform-origin: 0%;
}